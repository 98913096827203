import React, { useState, useEffect, Suspense } from "react"
import { Canvas } from "@react-three/fiber"
import { useGLTF, useTexture, Shadow } from "@react-three/drei"
import { animated, AnyFn, SpringValue, useSpring } from "@react-spring/web"
import { a } from "@react-spring/three"
import ball from "./images/icon_ball_5.png"

function Switch({ x, set, onClick }: { set: React.Dispatch<React.SetStateAction<number>>; x: SpringValue<number>; onClick:AnyFn }) {
  const { nodes, materials } = useGLTF("/switch.glb")
  const texture = useTexture(ball)
  // Hover state
  const [hovered, setHover] = useState(false)
  useEffect(() => void (document.body.style.cursor = hovered ? "pointer" : "auto"), [hovered])
  // Events
  
  const onPointerOver = () => setHover(true)
  const onPointerOut = () => setHover(false)

  // Interpolations
  const pZ = x.to([0, 1], [-1.1, 1.1])
  const rX = x.to([0, 1], [-0.9, Math.PI * 1.5])
  const color = x.to([0, 1], ["#888", "#2a2a2a"])

  return (
    <group scale={[1.25, 1.25, 1.25]} dispose={null} position={[3.4, 3, 4]}>
      <a.mesh
        receiveShadow
        castShadow
        material={materials.track}
        geometry={(nodes.Cube as any).geometry}
        material-color={color}
        material-roughness={0.5}
        material-metalness={0.8}
      />
      <a.group position-y={0.55} position-z={pZ}>
        <a.mesh
          receiveShadow
          castShadow
          rotation-x={rX}
          onClick={onClick}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        >
          <sphereGeometry args={[0.8, 64, 64]} />
          <a.meshStandardMaterial roughness={0.5} map={texture} color="#FFFFFF" />
        </a.mesh>
        <a.pointLight intensity={100} distance={1.4} color={color} />
        <Shadow
          renderOrder={-1000}
          position={[0, -1, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={1.5}
        />
      </a.group>
    </group>
  )
}

export default function App() {
  const [toggle, set] = useState(0)
  const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

  const color = x.to([0, 1], ["#c72f46", "#7fffd4"])
  const onClick = () => set((toggle) => Number(!toggle))

  return (
    <div onClick={onClick}>
      <div className="top-right-buttons">
        <button onClick={() => window.location.href = 'https://app.campusrank.in/login'}>
          Login
        </button>
        <button onClick={() => window.location.href = 'https://app.campusrank.in/jointest'}>
          Exam
        </button>
      </div>

      <div className="bottom-left-buttons" style={{color: toggle===1 ? 'black':'white'}}>
        <p className="line-one">Ranking Talent,</p>
        <p className="line-two">Building Futures</p>
      </div>

      <animated.div
        className="container"
        style={{ backgroundColor: x.to([0, 1], ["#ff2558", "#c9ffed"]), color: x.to([0, 1], ["#c70f46", "#7fffd4"]) }}
      >
        <h1 className="open" children="<campus>" />
        <h1 className="close" children="</rank>" />
        <animated.h1>{x.to((x) => (11.09 - x).toFixed(2))}</animated.h1>
        <Canvas orthographic shadows dpr={[1, 2]} camera={{ zoom: 60, position: [50, 50, 50], fov: 35 }}>
          <ambientLight intensity={0.4} />
          <directionalLight position={[-50, 50, 50]} intensity={1} />
          <a.directionalLight position={[-50, -50, -50]} intensity={1} color={color} />
          <a.pointLight position={[0, 0, 5]} distance={5} intensity={5} color={color} />
          <a.spotLight
            color={color}
            position={[10, -10, -20]}
            angle={0.1}
            intensity={2}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={2048}
            shadow-bias={-0.00001}
            castShadow
          />
          <Suspense fallback={null}>
            <Switch x={x} set={set} onClick={onClick}/>
          </Suspense>
          <mesh receiveShadow renderOrder={1000} position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[10, 10]} />
            <a.shadowMaterial transparent opacity={x.to((x) => 0.1 + x * 0.2)} />
          </mesh>
        </Canvas>
      </animated.div>
    </div>
  )
}
